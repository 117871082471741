<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<div style="margin-top: 20px;">
				<el-form ref="ruleFormRef" :model="form" :rules="rules" class="demo-ruleForm" :size="formSize"
					status-icon>
					<!-- 标题 -->
					<el-form-item label="标题名称" prop="name">
						<div class="aaaa">
							<el-input v-model="form.name" placeholder="请填写标题" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 短标题 -->
					<el-form-item label="短标题" prop="bywork">
						<div class="aaaa">
							<el-input v-model="form.bywork" placeholder="请填写短标题" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 简介 -->
					<el-form-item label="简介" prop="synopsis">
						<div class="aaaa">
							<el-input v-model="form.synopsis" placeholder="请填写简介" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 标题图片 -->
					<!-- <el-form-item  label="主题图片" prop="breviary">
          <el-upload action="/admin/news/saveImage" list-type="picture-card" limit="2" 
            ref="qingkong"
            v-model="form.breviary"
            :auto-upload="true" 
            :on-change="change" 
            :on-remove="Remove"
            :class="styA==0?'styB':'styC'"
            :before-upload="beforeImageUpload"
            :before-remove="beforeremove"
            :on-preview="onpreview"
            :headers="header"
            accept=".jpeg,.png,.jpg,.bmp,.gif"
            :data='{
              imageFile: file
              }'
            name="imageFile"
            :on-success="succ"
            >
           <el-icon><Plus /></el-icon>
          </el-upload>

          <el-dialog v-model="dialogVisible">
            <img w-full :src="dialogImageUrl" alt="Preview Image" style="width:100%"/>
          </el-dialog>
          <div class="referrals">
            ( 推荐：图片尺寸 宽260px、高160px。 )
          </div>
        </el-form-item> -->
					<!-- 分类 -->
					<el-form-item label="动态分类" prop="sex">
						<el-select v-model="form.sex" placeholder="请选择分类" size="medium">
							<el-option label="通知公告" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="最新资讯" value="2" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="规章制度" value="3" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 类别分类 -->
					<el-form-item label="类别分类" prop="category">
						<div class="aaaa">
							<el-input v-model="form.category" style="width: 218px" placeholder="例：1  2"
								size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 是否发布 -->
					<el-form-item label="是否发布" prop="are">
						<el-select v-model="form.are" placeholder="请选择是否发布" size="medium">
							<el-option label="是" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 上传附件 -->
					<el-form-item label="上传附件">
						<el-upload ref="uploadRef" v-model:file-list="fileList" class="upload-demo"
							action="/admin/news/saveFile" :auto-upload="true" :on-preview="handlePreview"
							:on-remove="onremove" :before-remove="cancel" :before-upload="paper" limit="2"
							:on-change="onchange" :headers="footer" name="file" :data='dese' :on-success="win"
							accept=".docx,.doc,.xlsx,.pdf,.xls,.ppt,.pptx,.jpeg,.png,.jpg,.bmp,.gif,.psd,.zip,.rar">
							<el-button type="primary" v-show="pictures">上传</el-button>
						</el-upload>
					</el-form-item>
					<!-- 编辑器 -->
					<el-form-item prop="copyreader">
						<div class="subject">
							<span class="xingxing">*</span>
							主体内容
							<span class="referrals">( 推荐：图片体积1M。 )</span>
						</div>
						<div style="border: 1px solid #ccc; width:100%">
							<Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef"
								:defaultConfig="toolbarConfig" :mode="mode" />

							<Editor style="height: 500px; overflow-y: hidden" v-model="valueHtml"
								:defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" />
						</div>
					</el-form-item>
				</el-form>
			</div>
			<el-button type="primary" size="large" style="margin-top: 20px;" :loading="ConfirmLoading"
				@click="onSubmit">提交</el-button>
		</el-card>
	</div>
</template>
<script>
	import '@wangeditor/editor/dist/css/style.css' // 引入 css
	import {
		onBeforeUnmount,
		ref,
		shallowRef,
		onMounted,
		reactive
	} from 'vue'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	import {
		Plus,
	} from '@element-plus/icons-vue'
	import * as imageConversion from 'image-conversion'
	import {
		ElMessage
	} from "element-plus";
	import {
		GHsubmit
	} from "../../utils/api"
	import {
		useRouter
	} from "vue-router";
	// import { Pictures } from '../../utils/api'
	import qs from 'qs'
	export default {
		name: "inform",
		components: {
			Editor,
			Toolbar,
			Plus,
		},
		setup() {
			let data = ref(null);
			// 按钮加载
			const ConfirmLoading = ref(false)
			// 上传附件
			// 获取上传文件地址
			let address = ref('')
			const win = (res) => {
				address.value = res.data
				console.log(res)
			}
			const files = ref('')
			const dese = ref({
				file: files
			})
			// 上传文件请求头
			const footer = ref({
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			})
			// 附件名称
			// 上传文件限制文件大小
			const paper = (File) => {
				console.log(File)
				files.value = File.name
				let isLt2Ms = File.size / 1024 / 1024 < 3 ? '1' : '0'
				if (isLt2Ms == '0') {
					files.value = ''
					ElMessage({
						message: '文件大小不能超过3M',
						type: "warning",
						center: true,
					});
				}
				return isLt2Ms === '1' ? true : false;
			}
			// 上传附件
			const pictures = ref(true)
			// 删除附件
			const cancel = (File, fileList) => {
				console.log(File, fileList.leng)
			}
			const onremove = (File, fileList) => {
				console.log(File, fileList.length)
				if (fileList.length == 0) {
					pictures.value = true
				}
			}
			// 文件状态改变钩子
			const onchange = (File, fileList) => {
				if (fileList.length > 0) {
					pictures.value = false
				}
			}
			// 点击上传文件钩子
			const handlePreview = (File) => {
				console.log(File);
			}









			// 上传图片
			const qingkong = ref('')
			const header = ref({
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			})
			// 获取上传图片成功地址
			let location = ref('')
			const succ = (res) => {
				location.value = res.data
				console.log(res)
			}
			// 自定义上传
			const httpreqst = (param) => {
				console.log(param)
				console.log(param.file.name)
				data.value = {
					imageFile: param.file.namefileList
				}
				// Pictures(data).then((res)=>{
				//   console.log(res)
				// })
			}
			const styA = ref(0)

			const change = (res, fileList) => {
				// console.log(res)
				styA.value = fileList.length
				form.breviary = fileList
				// console.log(fileList)
			}
			const beforeImageUpload = (File) => {
				console.log(File)
				let isLt2M = File.size / 1024 / 1024 < 1
				return new Promise((resolve) => {
					if (isLt2M) {
						resolve(File)
					}
					imageConversion.compressAccurately(File, 930).then((res) => {
						resolve(res);
						// data.value = File.name
					});
				})
			}
			// 图片放大
			const dialogVisible = ref(false)
			let dialogImageUrl = ref('')
			const onpreview = (File) => {
				dialogVisible.value = true
				dialogImageUrl.value = File.url
				console.log(File)
			}
			// 删除图片
			const beforeremove = (File, fileList) => {
				console.log(File)
				console.log(fileList)
				styA.value = 0
			}
			const Remove = (file) => {
				console.log(file)
			}
			const uploadRef = ref()

			// 提交 按钮
			const router = useRouter();
			const onSubmit = () => {
				// console.log(form.synopsis)
				// console.log(form.category)
				// console.log('标题',form.name)
				// console.log('副标题',form.bywork)
				// console.log('缩略图地址',location.value)
				// console.log('类型',form.sex)
				// console.log('是否发布',form.are)
				// console.log('上传附件',address.value)
				// console.log('附件名称',files.value)
				// console.log('编辑器内容',form.copyreader)
				if (valueHtml.value == '<p><br></p>') {
					form.copyreader = ''
				} else {
					form.copyreader = valueHtml.value
				}
				ruleFormRef.value.validate((valid) => {
					console.log(valid)
					let data = {
						// 标题
						title: form.name,
						// 副标题
						subtitle: form.bywork,
						// 简介
						introduce: form.synopsis,
						// 类型
						type: form.sex,
						// 类别
						category: form.category,
						// 图片
						imageAddress: location.value,
						// 是否公开
						status: form.are,
						// 编辑器内容
						content: form.copyreader,
						// 附件
						fileName: address.value,
						// 附件名称
						fileOriginalName: files.value
					}
					if (valid == true) {
						ConfirmLoading.value = true
						GHsubmit(qs.parse(data)).then((res) => {
							console.log(res)
							if (res.data.code == 0) {
								ElMessage({
									message: '提交成功',
									type: "success",
									center: true,
								});
								setTimeout(() => {
									router.push({
										name: 'dynamic',
										params: {
											userId: 1
										}
									});
									form.synopsis = ''
									form.category = ''
									form.name = ''
									form.bywork = ''
									location.value = ''
									styA.value = 0
									form.breviary = ''
									form.sex = ''
									form.are = ''
									address.value = ''
									files.value = ''
									form.copyreader = ''
									valueHtml.value = ''
									uploadRef.value.clearFiles()
									qingkong.value.clearFiles()
									pictures.value = true
									ConfirmLoading.value = false
								}, 500)
							} else {
								ElMessage({
									message: res.data.msg,
									type: "error",
									center: true,
								});
								ConfirmLoading.value = false
							}
						})
					} else {
						ElMessage({
							message: "提交失败",
							type: "error",
							center: true,
						});
						ConfirmLoading.value = false
					}
					// console.log(form)
				})
			}
			// 编辑器实例，必须用 shallowRef
			const editorRef = shallowRef()

			// 内容 HTML
			const valueHtml = ref('')

			// 模拟 ajax 异步获取内容
			onMounted(() => {})

			const toolbarConfig = {
				toolbarKeys: [
					'bold', // 加粗
					'italic', // 斜体
					'through', // 删除线
					'underline', // 下划线
					'bulletedList', // 无序列表
					'numberedList', // 有序列表
					'color', // 文字颜色
					'fontSize', // 字体大小
					'lineHeight', // 行高
					'uploadImage', // 上传图片
					'delIndent', // 缩进
					'indent', // 增进
					'deleteImage', //删除图片
					'justifyCenter', // 居中对齐
					'justifyJustify', // 两端对齐
					'justifyLeft', // 左对齐
					'justifyRight', // 右对齐
					'undo', // 撤销
					'redo', // 重做
					'clearStyle', // 清除格式
				],
				excludeKeys: [
					'insertImage', // 网络图片
					'bgColor', // 背景色
					'blockquote', // 引用
					'codeBlock', // 代码段
					'emotion', // 表情
					'fontFamily', // 字体
					'headerSelect', // 标题 
					'fullScreen', // 全屏
				],
			}
			// 富文本上传图片地址
			// let fils=ref('')
			// placeholder: '请输入内容...',
			const editorConfig = {
				MENU_CONF: {}
			}
			editorConfig.MENU_CONF['uploadImage'] = {
				// 上传图片的配置 
				// 接口
				server: '/admin/news/saveEditorImage',
				// 请求头
				headers: {
					timeout: 10000,
					'accessToken': sessionStorage.getItem('token')
				},
				maxFileSize: 1 * 1024 * 1024, // 1M
				//上传字段 
				fieldName: 'imageFile',
				// meta: {
				//   imageFile:fils
				// },
				// 最多可上传几个文件，默认为 100
				maxNumberOfFiles: 1,
				// 上传前回调
				onBeforeUpload(file) {
					console.log(file)
					let fileObj = Object.values(file)[0].data
					console.log(fileObj)
					// 压缩图片
					let isLt2M = Object.values(file)[0].data.size
					if (isLt2M < 1048576) {
						return true
					} else if (isLt2M > 1048576) {
						ElMessage({
							message: '图片大小不能超过1MB,请重新上传',
							type: "warning",
							center: true,
						});
						return false
					}
				},
				// 上传成功后回调
				onSuccess(file, res) {
					// console.log(file)
					console.log(res)
				},
				// 上传失败回调
				onError() {
					ElMessage({
						message: '图片大小不能超过1MB,请重新上传',
						type: "warning",
						center: true,
					});
				},
			}

			// 组件销毁时，也及时销毁编辑器
			onBeforeUnmount(() => {
				const editor = editorRef.value
				if (editor == null) return
				editor.destroy()
			})

			const handleCreated = (editor) => {
				editorRef.value = editor // 记录 editor 实例，重要！
				// console.log(editor)
			}
			// 标题验证
			const ruleFormRef = ref(null)
			const form = reactive({
				name: "",
				sex: "",
				breviary: "",
				copyreader: "",
				are: '',
				bywork: '',
				category: '',
				synopsis: ''
			})
			// 验证表单提示
			const rules = {
				bywork: [{
					required: true,
					message: "请填写短标题",
					trigger: "change"
				}, ],
				are: [{
					required: true,
					message: "请选择是否发布",
					trigger: "change"
				}, ],
				copyreader: [{
					required: true,
					message: "请填写内容",
					trigger: "change"
				}, ],
				// breviary:[
				//   { required: true, message: "请上传缩略图", trigger: "change" },
				// ],
				name: [{
					required: true,
					message: "请填写标题",
					trigger: "blur"
				}, ],
				sex: [{
					required: true,
					message: "请选择分类",
					trigger: "change"
				}, ],
			}
			return {
				qingkong,
				uploadRef,
				header,
				succ,
				data,
				// 按钮加载
				ConfirmLoading,
				// 上传附件
				win,
				dese,
				footer,
				paper,
				onremove,
				cancel,
				onchange,
				pictures,
				handlePreview,
				// 上传图片
				httpreqst,
				dialogVisible,
				dialogImageUrl,
				onpreview,
				beforeremove,
				Remove,
				styA,
				change,
				beforeImageUpload,
				// handlePictureCardPreview,
				// 编辑器
				onSubmit,
				editorRef,
				valueHtml,
				mode: 'default',
				toolbarConfig,
				editorConfig,
				handleCreated,
				// 标题验证
				ruleFormRef,
				form,
				rules
			}
		}
	}
</script>
<style lang='scss' scoped>
	::v-deep .referrals {
		color: #cccccc;
		margin-left: 5px;
		font-weight: 500;
	}

	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	::v-deep .chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	::v-deep .subject {
		margin-left: 3px;
		font-size: var(--el-form-label-font-size);
		font-weight: bold;
		margin-bottom: 5px;
		color: var(--el-text-color-regular);

		.xingxing {
			color: var(--el-color-danger);
		}
	}

	// .w-e-text-container [data-slate-editor] p{
	//   margin-top: 22px;
	//   margin-bottom: 22px;
	// }
	::v-deep .el-button {
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	::v-deep .el-form-item__content {
		align-items: flex-end;
	}

	::v-deep .referrals {
		color: #cccccc;
		margin-left: 5px;
	}

	::v-deep .styB .el-upload--picture-card {
		width: 110px;
		height: 110px;
		line-height: 110px;
	}

	::v-deep .styC .el-upload--picture-card {
		display: none;
	}

	::v-deep .el-form-item {
		align-items: center;
	}

	::v-deep .aaaa {
		width: 50%;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	::v-deep .el-main {
		overflow: auto !important;
	}

	::v-deep .el-card.is-always-shadow {
		width: 95%;
		margin: auto;
		margin-top: 10px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		overflow: auto !important;
	}
</style>
<style scoped>
	::v-deep .upload-demo {
		display: flex;
		width: 100%;
	}

	::v-deep .upload-demo .el-upload-list {
		display: flex;
		width: 50%;
	}

	::v-deep .el-input {
		height: 44px !important;
	}

	::v-deep .el-form-item__label {
		width: 80px !important;
		font-weight: bold;
	}
</style>